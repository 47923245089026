import React, { useEffect, useRef, useState } from "react"
import useWindowSize from "../../../hooks/useWindowSize"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import CommonLink from "../CommonLink"

const SpecialRooms = ({
  data,
  image,
  title,
  feature,
  id,
  index,
  specialOffer,
  pageClass,
}) => {
  const { width } = useWindowSize()
  const thumRef = useRef(null)
  const [swidth, setSWidth] = useState("auto")
  const getListSize = () => {
    const newWidth = thumRef.current.clientWidth
    setSWidth(newWidth)
  }

  useEffect(() => {
    getListSize()
  }, [width])
  useEffect(() => {
    window.addEventListener("resize", getListSize)
  }, [])
  return (
    <div
      className="rooms_wrap"
      data-aos="fade-up"
      data-aos-delay={`${100 + index * 50}`}
      data-aos-duration={`${1000 + index * 100}`}
      ref={thumRef}
    >
      <CommonLink
        link={
          specialOffer ? `/hotel-special-offers/${id}` : data?.detailButton.url
        }
        type={specialOffer ? "internal" : ""}
      >
        <ImageWrapper
          width={swidth}
          height={width < 768 ? 0.89 * swidth : 1.02 * swidth}
          image={image}
          loading="eager"
          className={`rooms_thum ${pageClass}`}
        />
      </CommonLink>
      <div className={`rooms_text mt-3`}>
        <h4>{title}</h4>
        <span>{feature}</span>
        {specialOffer ? (
          <CommonLink
            link={`/hotel-special-offers/${id}`}
            type="internal"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            VIEW DETAILS
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="9"
              viewBox="0 0 6 9"
              fill="none"
              style={{ marginLeft: 5 }}
            >
              <path
                d="M1 1L5 4.5L1 8"
                stroke="#002643"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </CommonLink>
        ) : (
          <CommonLink
            link={`/hotel-special-offers/${id}`}
            type="internal"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            VIEW DETAILS
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="9"
              viewBox="0 0 6 9"
              fill="none"
              style={{ marginLeft: 5 }}
            >
              <path
                d="M1 1L5 4.5L1 8"
                stroke="#002643"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </CommonLink>
        )}
      </div>
    </div>
  )
}

export default SpecialRooms
const ImageWrapper = styled(GatsbyImage)`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  margin-top: 20px;
`
